
.popup-layer-container { z-index: 10000; display: flex; flex-direction: column; justify-content: space-between; position: absolute; background: #fff; box-shadow: 0px 0px 20px 0px rgba(0,0,0,.25); animation: popup-fade .35s ease-out .35s forwards; opacity: 0; }
.popup-layer-body { height: 100%; background: #fff; display: block;}
.popup-layer-content { font-size: 15px; }
.popup-layer-content img { margin-right: -5px; margin-left: -5px; max-width: calc(100% + 10px); object-fit: cover;}
.popup-layer-foot { display: flex; align-items: center; justify-content: space-between; padding: 12px 10px; background: #424242; }
.popup-layer-foot .check { cursor: pointer; }
.popup-layer-foot .check .text,
.popup-layer-foot .button { padding: 0; font-size: 15px; color: #ffffff; cursor: pointer; }
::v-deep{
    .popup-layer-body{
        *{
            display: block;
            width: 100%;
            height: 100%;
            line-height: 0;
        }
        img {      
            object-fit: cover;
        }
    }
    .theme--dark.v-label {
        color: #fff;
    }
    .v-btn:not(.v-btn--fab,.v-btn--icon).v-size--default {
        height: auto;
        padding: 6px;
        margin: -6px;
        min-width: 0;
    }
}
@-webkit-keyframes popup-fade{
    0% { opacity: 0; -webkit-transform: translateY(25px); transform: translateY(25px); }
    100% { opacity: 1; -webkit-transform: translateY(0); transform: translateY(0); }
}
@keyframes popup-fade{
    0% { opacity: 0; -webkit-transform: translateY(25px); transform: translateY(25px); }
    100% { opacity: 1; -webkit-transform: translateY(0); transform: translateY(0); }
}
@media(min-width:1025px){
    .popup-layer-foot .check:hover .text,
    .popup-layer-foot .button:hover { opacity: .7; }
}
@media (max-width: 768px){
    .popup-layer-container { top: 60px !important; left: 0 !important; margin: 0 5px; width: calc(100% - 10px) !important; height: auto !important; }
    .popup-layer-content { font-size: 13px; }
    .popup-layer-content img { height: auto !important; }
    .popup-layer-foot .check .text,
    .popup-layer-foot .button { font-size: 13px; }        
    ::v-deep{
        .popup-layer-body{
            *{
                height: auto !important;
            }
        }
    }
}
